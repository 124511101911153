*{
  box-sizing:border-box;
  margin:0;
  padding:0;
  font-family: 'Brown Regular';
}

.wrapper{
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
}

body {
  min-width: 300px;
  color: #000;
  background-color: #FFF;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400; /* regular */
  font-size: 62.5%; /* 10px */
  line-height: 1.2;
  overflow: auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
}

img {
	max-width: 100%;
	height: auto;
	border: 0;
}

a {
  text-decoration: none;
}

h3 {
	margin: 0 0 1.5em 0;
	color: #299ed5;
	font-size: 1.7em;
	font-weight: 700;
}

p + h3, ul + h3, ol + h3 {
	margin-top: 2.5em;
}

p {
  font-size: 1.5em;
  text-align: left;
}

p:last-child {
  margin-bottom: 0;
}

.content ol {
	margin: 0 0 1em 8em;
	list-style: decimal outside;
}

.content ul {
	margin: 0 0 1em 8em;
	list-style: disc outside;
}

.content li {
  margin: 0 0 0.8em 0;
  font-size: 1.5em;
}

.content li p, .content p li, .content p p,
.content li li, .content th p, .content td p,
.content th li, .content td li {
  font-size: 1em;
}

.content th ul, .content th ol,
.conten td ul, .content td ol {
	margin-left: 1em;
}

strong {
	font-weight: 700;
}
em {
	font-style: italic;
}
s, strike, del {
	text-decoration: line-through;
}
sup {
	vertical-align: sub;
	font-size: smaller;
}
sup {
	vertical-align: super;
	font-size: smaller;
}
span.description {
	color: transparent;
	font: 0/0 a;
}

table {
	table-layout: fixed;
	width: 100%;
}

html {
  scroll-behavior: smooth;  
}

.footer.project{
  background-color: #FAD2D8;
}

.footer.contact{
  background-color: #DEF9FF;
}

.footer.project-detail{
  background-color: #CEF169;
}

.nav-filter-button.project-detail-button .project-filter-button{
  opacity: 0.3;
}

.nav-filter-button.digital .project-filter-button:first-child{
  opacity: 1;
}

.nav-filter-button.graphic .project-filter-button:nth-child(2){
  opacity: 1;
}

.nav-filter-button.print .project-filter-button:last-child{
  opacity: 1;
}