.contact-bg{
    background-color: #FFDD0F;
}

.contact-section{
    padding-top: 70px;
    display: flex;
    padding-bottom: 70px;

}

.contact-left{
    width: 50%;
    padding-right: 20px;
}

.contact-right{
    width: 50%;
    padding-left: 20px;
}

.contact-heading img{
    width: 300px
}

.contact-heading{
    padding-bottom: 50px;
}

.contact-address p{
    font-size: 2.4em;
    padding-bottom: 20px ;
    color: #333333;
}

.contact-address p:first-child{
    padding-bottom: 40px;
}

.contact-map{
    padding-top: 20px;
}

.contact-form input, .contact-form textarea, .contact-form select{
    background-color: unset;
    border: 0;
    padding: 10px 0;
    font-size: 2.3em;
    color: #093F25;
    width: 100%;
    border-bottom: solid 2px #093F25;
}

.contact-form input:focus , .contact-form textarea:focus , .contact-form select:focus{
    border: 0;
    outline: none;
    border-bottom: solid 2px #093F25;
}

.contact-form option{
    padding: 5px 0;
    background-color: #FFDD0F;
    color: #093F25;
    font-size: 0.9em;
}

.contact-form option:focus{
    background-color: #FFDD0F;
    color: #86a697;
}

.contact-form input::placeholder , .contact-form textarea::placeholder{
    color: #093F25;;
    opacity: 1; 
  }
  
  .contact-form input:-ms-input-placeholder, .contact-form textarea:-ms-input-placeholder { 
    color: #093F25;
  }
  
  .contact-form input::-ms-input-placeholder, .contact-form textarea::-ms-input-placeholder { 
    color: #093F25;
  }

.contact-form-heading{
    color: #093F25;
    font-size: 2.3em;
    padding-bottom: 90px;
}
.contact-form-heading h1{
    font-size: inherit;
    font-weight: inherit;
}

input.submit-button{
    width: fit-content;
    border-bottom: 0;
    position: relative;
    cursor: pointer;
    padding-right: 40px;
    z-index: 2;
    margin-top: 10px;
}

input.submit-button:focus{
    border-bottom: 0;

}

.form-submit{
    position: relative;
}

.form-submit:after{
    position: absolute;
    content: '';
    top:50%;
    transform: translateY(-50%);
    width: 10px;
    height: 18px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAsCAYAAABloJjNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAfpJREFUeNq0lzFOw0AQRW1HFHSRuIApkCidE5B0lHFHmdwgvkE4QbhBcgNzgyQdnU0HoojTQYOcCgoK/qBZaVitTXYsVlp5d4qvmbczs+sg+K9xenXRx0y66kQsFuOzwyywXnQWxCDBPq9nEB1rBUMRcoGPCbnGPP/YvtRaD2mkLBSwt7nGw55ZfO3f65P47BnLG4MB+xD2jUqQRZ8gEovQh9hvYa80IZuRYZZin1NKqQX5IKZanj2XESG+ItQ3LMeC5wH2h6PTpqF6ciFKY4AISl+GclDolQ/PVkHmmQoTZcDSm6GD5wHLazZdtvEMPbqR5Emej1w8I48ikDyJ49LF82hBkZ9mUDUtvBlaPCuqbypJI4r9HvbSm6HFcy1Ef/GMlH3UbnVLzaG05Wdirg6thyRKfXIlTLNOgnyxyTrfdBLkltYXBzNVC8K7uejqP0kPBJUqbSBG6bIWpjuIZao85FLbiVBLiA20tdzITSXI3IbyMnN1m1DJbQWxqeYKMNxkly75qlXdKQGLxVaK1CpBeDezqiH769YLW8QocQthuodYqnmKGG7ytVC5UsQn5IXFLT32rRg5vJvgM/Hh1siQua1FNWwgNlK9YEW+ydJKfZtHZHFLNNxcvxWJxe2WW3zQxcNAcJtr23hPPIgesaRHe4b9p1bwW4ABAG3nyZiPWaBaAAAAAElFTkSuQmCC);
    margin-left: -10px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 6px;
    z-index: 1;
}



.contact-enquiry{
    margin-top: 70px;
    font-size: 3.5em;
    color: #404040;
}

.contact-enquiry-small{
    margin-top: 50px;
    font-size: 2em;
    color: #404040;
}
.contact-enquiry-small h2{
    font-size: inherit;
    font-weight: inherit;
    margin-bottom: 0.5em;
}
.contact-enquiry-small p{
    font-size: inherit;
}

.email-message{
    background-color: #DFF0D8;
    color: #093F25;
    width: 100%;
    border-radius: 3px;
    padding: 15px 20px;
    font-size: 2em;
    margin-top: 20px;
}