body .footer {
    background-color: #FFDD0F;
    padding: 60px 0;
  }
  
  .footer-col {
    color: #404040;
  }
  
  .footer-col ul {
    list-style: none;
  }
  
  .footer-col li {
    font-size: 2.3em;
    font-weight: 700;
    line-height: 1.4;
    padding-left: 17px;
    margin-bottom: 0;
  }
  
  .footer-col li:before {
    color: #ED1C24;
    content: "·";
    left: 0;
    top: 2px;
  }
  
  .footer-col li a {
    color: #333;
  }
  
  .footer-col p {
    font-size: 2.3em;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 0;
  }
  
  .footer-col-1 {
    width: 25.19%;
  }
  
  .footer-col-2 {
    width: 12.59%;
  }
  
  .footer-col-3 {
    width: 31.48%;
  }
  
  .footer-col-4 {
    width: 30.74%;
  }
  
  .footer-logo {
    width: 276px;
    height: 92px;
  }
  
  .copyright {
    color: #404040;
    font-size: 1.6em;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 0.55em;
    letter-spacing: 0;
  }