.home-section-3 a.home-section-more, a.home-service-more, .home-about-more {
  display: inline-block;
}

.home-service li:before, .home-about-more:after, .home-section-1:after, .footer-logo, .watermelon span {
  display: block;
}

.home-service li:before, .home-about-more:after, .home-section-1:after, .home-section-1:before {
  content: "";
}

.home-service li, .home-section-intro, .footer-col {
  float: left;
}

.home-section-details {
  float: right;
}

.home-service ul, .home-section, .footer-cols {
  overflow: hidden;
}

.home-service li:before, .home-service h3 i, .home-about-more:after, .home-section-1:after, .footer-col li:before {
  position: absolute;
}

.home-service li, .home-service h3, .home-about-more, .home-about, .home-section-1, .footer-col li {
  position: relative;
}

.home-about-more:after {
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.home-service h3 i {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.home-section-1:after, .home-section-1 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.home-service li:before, .home-service h3 i, .home-about-more:after, .watermelon span {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.home-section-1:before {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.home-section-3 a.home-section-more, a.home-service-more {
  text-align: center;
}

.home-service-more-container {
  text-align: right;
}

/* 1596, 88.5 * 16, 12 * 15 */
body {
  letter-spacing: -0.1px;
}

.wrapper {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
}

.watermelon {
  position: fixed;
  display: block;
  right: 35px;
  bottom: 35px;
  width: 8vw;
  z-index: 99;
}

.watermelon span {
  background-image: url("../../images/watermelon@2x.png");
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

/* HOME */
.home-section-intro {
  width: 26%;
}

.home-section-details {
  width: 64%;
}

.home-section-1 {
  background-image: url("../../images/home-section-image.jpg");
  background-position: left top;
}

.home-section-1:before {
  background: -moz-linear-gradient(45deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(45deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(45deg, black 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#00000000",GradientType=1 );
  z-index: 1;
}

.home-section-1:after {
  background-image: url("../../images/home-section-arc@2x.png");
  width: 100%;
  height: 0;
  padding-bottom: 7.5%;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.home-about {
  padding-top: 130px;
  /* temp */
  padding-bottom: 38px;
  /* temp */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
  z-index: 2;
}

.home-about-text {
  border-left: 2px solid #FFDD0F;
  padding-left: 24px;
  margin-bottom: 80px;
  /* temp */
}

.home-about-text h2 {
  color: white;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 250px;
  /* temp */
}

.home-about-text p {
  color: white;
  font-size: 6.4em;
  font-weight: 400;
  line-height: 1;
}

.home-about-more-container {
  text-align: center;
  padding: 70px 0;
}

.home-about-more {
    color: white;
    font-size: 1.8em;
    font-weight: 700;
    line-height: 1.3;
    padding-bottom: 49px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
    bottom: 40px;
    cursor: pointer;
}

.home-about-more:after {
  background-image: url("../../images/arrow-down-white@2x.png");
  bottom: 0;
  width: 46px;
  height: 19px;
}

.home-section-2 h2,
.home-section-3 h2 {
  color: #093F25;
  font-size: 6.4em;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 40px;
}

.home-section-2 p {
  color: #404040;
  font-size: 3.8em;
  font-weight: 400;
  line-height: 1.1;
}

.home-section-3 p {
  color: #404040;
  font-size: 2.8em;
  font-weight: 400;
  line-height: 1.4;
}

.home-section-2 {
  background-image: url("../../images/home-section-bg@2x.png");
  padding-top: 100px;
  background-repeat: repeat;
  background-position: left top;
  position: relative;
}

.home-serices {
  border-bottom: 1px solid #ED1C24;
}

.home-service {
  border-top: 1px solid #ED1C24;
}

.home-service h3 {
  color: #093F25;
  font-size: 3.8em;
  font-weight: 400;
  line-height: 1.1;
  padding: 20px 20px 20px 72px;
  margin: 0;
}

.home-service h3 i {
  left: 0;
}

.home-service-1 h3 i {
  background-image: url("../../images/icon-web@2x.png");
  width: 43px;
  height: 40px;
}

.home-service:last-child{
    border-bottom: solid 1px #ED1C24;
    margin-bottom: 120px;
}

.home-service-2 h3 i {
  background-image: url("../../images/icon-design@2x.png");
  width: 46px;
  height: 52px;
}

.home-service-3 h3 i {
  background-image: url("../../images/icon-print@2x.png");
  width: 40px;
  height: 54px;
}

.home-service ul {
  padding: 30px 0;
  list-style: none;
}

.home-section-4{
    padding:20px 0;
}

.home-logos{
    display: flex;
    flex-wrap: wrap;

}

.home-logo{
    width: 18%;
    margin-right: 2.25%;
    margin-bottom: 50px;
}

.home-logo:nth-child(5n){
    margin-right: 0;

}

.home-service li {
  color: #404040;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.8;
  width: 33%;
  padding-left: 22px;
  padding-right: 30px;
  margin-bottom: 10px;
}

.home-half-watermelon{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 35%;
    max-width: 500px;
    padding-bottom: 29%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../images/half-watermelon.png");
    background-position: left bottom;
}

.home-service li:before {
  background-image: url("../../images/bullet@2x.png");
  left: 0;
  top: 11px;
  width: 8px;
  height: 10px;
}

/*
.home-service li:before {
	@extend %abs, %b;
	@include c(red);
	content: '>';
	left: 0;
	top: 0;
}
*/
.home-service-more-container {
  padding: 0 0 45px 0;
}

a.home-service-more {
  color: #093F25;
  background-color: white;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  max-width: 206px;
  padding: 10px 20px;
  border-radius: 15px;
}

.home-section-3 {
  padding: 100px 0 150px 0;
}

.home-section-3 .home-section-more-container {
  padding-top: 50px;
}

.home-section-3 a.home-section-more {
  color: white;
  background-color: #093F25;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  max-width: 206px;
  padding: 10px 20px;
  border-radius: 15px;
}
