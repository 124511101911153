.mobile-menu-content{
    display: none;
}

@media (max-width:1510px) {
    .nav-bottom-left{
        font-size: 2em;
    }

    .project-filter-button {
        padding: 20px 30px 20px 60px;
        background-color: unset;
    }

    .project-filter-button:before{
        width: 50px;
        height: 50px;
    }

    .nav-top {
        padding-bottom: 30px;
    }

}

@media (max-width:1250px) {
    .footer-cols{
        display: flex;
        flex-wrap: wrap;

    }

    .footer-col{
        width: 50%;
        padding-bottom: 50px;
    }

    .nav-bottom-left {
        width: 60%;
        padding-right: 20px;
        color: #404040;
    }

    .nav-project-detail h3{
        font-size: 0.6em;
    }

    .nav-project-detail h4{
        font-size: 0.6em;
    }

    .nav-bottom-right {
        width: 40%;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .footer-col-3{
        padding-right: 20px;
    }

    .nav-filter{
        font-size: 0.7em;
    }

    .project-filter-button{
        font-size: 0.6em;
    }

    .nav-bottom-right {
        text-align: right;
    }

    .nav-brand {
        align-self: self-end;
    }

    .project-filter-button {
        padding: 20px 17px 20px 55px;
        background-color: unset;
        color: #052B18;
    }


}

@media (max-width:1150px) {
    .project-filter-buttons{
        flex-wrap: wrap;
    }
}


@media (max-width:1000px) {  
    .home-half-watermelon {
        max-width: 180px;
    }
    .home-about-text h2 {
        margin-bottom: 100px;
    }

    .project-location {
        width: 55%;
    }

    .project-year {
        width: 20%;
    }

    .project-industry {
        width: 25%;
    }

    .project-detail-heading .home-service-more{
        display: none;
    }
    .mobile-link{
        display: flex;
    }

    .navbar-container{
        flex-direction: column;
    }

    .nav-left{
        width: 100%;
        padding-top: 20px;
        padding-left: 0;
        padding-bottom: 20px;
    }

    .nav-right{
        width: 100%;
    }

    .nav-bottom {
        flex-direction: column-reverse;
    }

    .nav-bottom-right{
        width: 100%;
        padding: 10px 0;
        border-top: solid 3px #B3B3B3;
        border-bottom: solid 3px #B3B3B3;
        align-items: center;
        flex-direction: row-reverse;
    }

    .nav-project{
        text-align: right;
        padding: 35px 15px;
    }

    .nav-brand {
        align-self: center;
    }

    .nav-bottom-right-link:after{
        width: 30px;
        height: 25px;
        top: 35%;
        transform: translateY(-50%);
    }

    .nav-brand img {
        width: 230px;
        height: auto;
        align-self: self-end;
    }

    .nav-top{
        position: fixed;
        display: none;
    }

    .nav-bottom-left{
        padding-top: 25px;
        font-size: 2.3em;
        width: 100%;
        padding-right: 0;
    }

    .home-section-intro{
        width: 100%;
    }

    .contact .nav-brand img {
        margin: 10px 0;
    }

    .nav-filter {
        font-size: 1.2em;
    }

    .project-filter-button {
        font-size: 1em;
    }

    .home-section-details{
        width: 100%;
        padding-top: 30px;
    }

    .home-section-2 p{
        font-size: 3em;
    }

    .home-section-2 h2, .home-section-3 h2{
        font-size: 5em;
    }

    .home-section-2{
        padding-bottom: 80px;
    }
    
    .home-section-3 p{
        font-size: 2.5em;
    }

    .home-section-more-container{
        text-align: center;
    }

    .home-service-more-container{
        text-align: center;
    }

    .contact-section{
        display: block;
    }

    .contact-left{
        width: 100%;
        padding-right: 0;
    }

    .contact-right{
        padding-left: 0;
        width: 100%;
        padding-top: 50px;
    }

    .contact-enquiry{
        font-size: 3em;
    }

    .watermelon {
        width: 70px;
    }

    .nav-project-detail h3 {
        font-size: 1em;
    }

    .nav-project-detail h4 {
        font-size: 1em;
    }

    .nav-bottom-right-link:after{
        width: 20px;
    }

    .mobile-menu-button{
        background-image: url(./images/menu-icon@2x.png);
        background-size: contain;
        width: 27px;
        height: 27px;
        position: absolute;
        top: 45px;
        transform: translateY(-50%);
        right: 5%;
        display: block;
        background-repeat: no-repeat;
        z-index: 99;

    }

    .mobile-menu-close{
        background-image: url(./images/menu-close@2x.png);
        background-size: contain;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 25px;
        right: 5%;
        display: block;
        background-repeat: no-repeat;
        z-index: 100;
    }

    .nav-left{
        position: relative;
    }

    .nav-top.active {
        position: fixed;
        display: block;
        background-color: #052B18;
        width: 100%;
        height: 100%;
        overflow-y: unset;
        z-index: 100;
        opacity: 0.9;
    }

    .nav-menu{
        flex-direction: column;
        padding-top: 70px;
    }

    .nav-item{
        width: 100%;
        padding: 0!important;
    }
    .nav-item a{
        padding: 15px 30px;
        display: block;
        color: #FFF;
        font-size: 2.5em;
        border-top: solid 1px #FFF;
        border-bottom: solid 1px #FFF;
        font-weight: 600;
        position: relative;
    }

    .nav-item a:after{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;
        width: 15px;
        height: 23px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("./images/menu-item-arrow.png");
    }

    .nav-links.active:before{
        background-image: url('./images/menu-item-acive-arrow.png');
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;
        width: 15px;
        height: 23px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .nav-links.active{
        background-color: #FFF;
        color: #052B18;
        border-bottom: 0;
        border-top: 0;
    }

    .nav-links.active:after{
        display: none;
    }

    .navbar-logo img{
        width: 130px;
    }

    .mobile-menu-logo{
        margin-top: 130px;
        width: 230px;
    }

    .mobile-menu-content{
        display: block;
        width: 90%;
        margin: 0 auto;
    }

    .mobile-menu-content p{
        font-size: 1.8em;
        color: #FFF;
        padding-top: 30px;
    }

    .mobile-menu-icon{
        display: block;
        padding-top: 60px;
    }

    .mobile-menu-icon img{
        width: 120px;
    }
    
}

@media (max-width:700px) {
    .footer-col {
        width: 100%;
        padding-bottom: 30px;
    }

    .footer-logo {
        margin: 0 auto;
        width: 150px;
    }

    .footer-col li:before {
        left: unset;
        top: -1px;
        margin-left: -20px;
    }

    .footer-col ul {
        text-align: center;
    }

    .footer-col p {
        font-size: 2em;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 0;
        text-align: center;
    }

    .copyright{
        text-align: center;
        padding-top: 50px;
    }

    body .footer {
        background-color: #FFDD0F;
        padding: 50px 0 30px 0;
    }

    .project-related{
        padding-bottom: 80px;
    }

    .project-block {
        width: 100%;
        padding: 50px 20px 30px 20px;
    }

    .project-related-block:last-child {
        display: none;
    }

    .project-related-block:nth-child(2){
        margin-right: 0;
    }

    .project-related-block {
        flex: 1 1;
        margin: 0 15px;
        max-width: 50%;
    }

    .project-related .project-return-link{
        bottom: 30px;
        top: unset;
        left: 50%;
        transform: translateX(-50%);
    }

    .home-logo {
        width: 31.33%;
        margin-right: 2.25%;
        margin-bottom: 20px;
    }

    .home-logo:nth-child(5n) {
        margin-right: 2.25%;
    }

    .home-logo:nth-child(3n) {
        margin-right: 0;
    }
   

    
}

@media (max-width:500px) {
    .project-detail-image{
        margin: 0!important;
        width: 100%!important;
        padding-bottom: 100%;
        margin-bottom: 20px!important;
    }
    .contact-form input, .contact-form textarea, .contact-form select {
        font-size: 1.8em;
    }

    .nav-bottom-left {
        padding-top: 25px;
        font-size: 1.6em;
        width: 100%;
    }

    .nav-bottom-right-link{
        font-size: 1.5em;
    }

    .home-about-text p {
        font-size: 3.5em;
    }

    .home-about-more{
        font-size: 1.5em;
    }

    .home-section-2 h2, .home-section-3 h2 {
        font-size: 3em;
    }

    .home-section-2 p {
        font-size: 1.8em;
    }

    .home-service h3 {
        font-size: 2.5em;
    }

    .home-service li {
        width: 50%;
        font-size: 1.6em;
    }

    .home-service:last-child {
        margin-bottom: 30px;
    }

    .home-section-3 p {
        font-size: 1.8em;
    }

    .home-section-3 {
        padding: 100px 0 100px 0;
    }

    .footer-col p {
        font-size: 1.7em;
    }

    .watermelon {
        width: 50px;
    }

    .contact-enquiry {
        font-size: 2.1em;
    }

    .contact-heading img {
        width: 200px;
    }

    .contact-address p {
        font-size: 1.8em;
        padding-bottom: 20px;
        color: #333333;
    }

    a.home-service-more{
        padding: 10px 10px;
    }

    .project-title {
        padding-right: 15px;
    }

    .home-logo {
        width: 48.5%;
        margin-right: 2.25%;
        margin-bottom: 10px;
    }

    .home-logo:nth-child(3n) {
        margin-right: 2.25%;
    }

    .home-logo:nth-child(2n) {
        margin-right: 0;
    }

    .project-bottom {
        flex-direction: column;
    }

    .project-title {
        padding-right: 0;
        width: 100%;
    }

    a.home-service-more{
        
        margin: 0 auto;
        margin-top: 30px;
        width: fit-content!important;
        padding: 10px 20px;
    }

    .project-bottom {
        padding-top: 20px;
        display: flex;
    }

    .project-title h3 {
        font-size: 1.5em;
    }

    .project-title h5 {
        font-size: 1.5em;
        color: #404040;
    }

    .project-filter-button {
        font-size: 0.8em;
        padding: 20px 10px 20px 45px;
    }

    .project-filter-button:before {
        width: 40px;
        height: 40px;
    }

    .nav-project-detail h4 {
        font-size: 0.8em;
    }

    .nav-project-detail h3 {
        font-size: 0.8em;
    }

  

    

}

@media (max-width:400px) {
    .nav-project {
        text-align: right;
        padding: 25px 15px;
    }

    .home-service li {
        padding-right: 20px;
    }

    .home-about-text p{
        font-size: 3.5em;
    }

    .project-related .project-return-link {
        bottom: 20px;
        top: unset;
        left: 50%;
        width: fit-content;
        transform: translateX(-50%);
    }
}



body div{
    animation: fade-in  ease-in-out;
    animation-duration: 0.2s;
}
.home-about-text, .home-about, .home-section-1 .home-section-wrapper{
    animation: none;
}

@keyframes animate{
    0%{
        opacity: 0;
        transform: translateY(0);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

