.navbar-container{
    display: flex;
    padding-bottom: 30px;
    width: 90%;
    margin: 0 auto;
    max-width: 1600px;
}

.nav-left{
    width: 50%;
    align-self: flex-end;
}

.nav-right{
    width: 50%;
}

.nav-top{
    padding-bottom: 50px;
}

.navbar-logo img{
    width: 200px;
}

.nav-menu{
    display: flex;
}

.nav-menu li{
    display: block;
    padding: 15px 20px; 
    position: relative;
}

.nav-menu li:first-child{
    padding-left: 0;
}

.nav-links{
    color: #093F25;
    text-decoration: unset;
    font-size: 2em;
    position: relative;
    padding-top: 15px;
}

.nav-bottom{
    display: flex;

}

.nav-bottom-left{
    width: 75%;
    font-size: 3em;
    padding-right: 50px;
    color: #404040;
}

.nav-bottom-right{
    width: 25%;
    display: flex;
    flex-direction: column;
    text-align: left;
   
}

.nav-bottom-right-link{
    font-size: 1.8em;
    color: #093F25;
    text-decoration: unset;
    border-bottom: solid 1px #EE4137;
    position: relative;
   padding-bottom: 4px;
}

.nav-links.active:after{
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #EE4137;
}

.nav-bottom-right-link:after{
    position: absolute;
    content: '';
    width: 10px;
    height: 18px;
    background-image: url(../../../images/nav-project-arrow.png);
    margin-left: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 3px;
}

.nav-project{
    flex:1
}

.nav-brand{
    justify-self: flex-end;
    flex: 1 1;
    display: flex;
}

.nav-brand img{
   width: 150px;
    height: auto;
    align-self: self-end;
}

.mobile-menu-button, .mobile-menu-close{
    display: none;
    cursor: pointer;
}

.nav-top-left{
    width: 50%;
}

.navbar-container:first-child{
    padding-bottom: 0;
    width: 100%;
}

.navbar{
    position: relative;
}

.contact .nav-bottom-left, .contact .nav-project{
    display: none;
}

.contact .nav-bottom {
    display: flex;
    justify-content: flex-end;
}

.contact .nav-brand img {
    width: 200px;
    height: auto;
    align-self: self-end;
}